import { type WrapPageElementBrowserArgs } from 'gatsby';

import { Cookiebot } from '@/components/Cookiebot';
import { Gtag } from '@/components/Gtag';
import { MetaPixel } from '@/components/MetaPixel';

export function wrapPageElement({ element }: WrapPageElementBrowserArgs) {
  return (
    <>
      <Cookiebot />
      <Gtag />
      <MetaPixel />
      {element}
    </>
  );
}