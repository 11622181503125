import { Script } from 'gatsby';

const GTAG_ID = 'G-9GGE1KSHHW';

export function Gtag() {
  return (
    <>
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`}
      />

      <Script>{`window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', '${GTAG_ID}', { 'anonymize_ip': true });`}</Script>
    </>
  );
}
